

























































































































































































































import { Vue, Component } from "vue-property-decorator";
import helper from "@/utils/helpers";
import {
  MetaModule as metaStore,
  VehicleScheduleModule as scheduleStore,
} from "@/store/modules";

import { AdminRouter } from "@/utils/routePathContsant";

@Component({
  components: {},
})
export default class VehicleScheduleClass extends Vue {
  scheduleList: any[] = [];
  filter: {
    page: number;
    vehicle_owner?: number;
    source?: number;
    destination?: number;
    shift?: string;
  } = {
    page: 1,
  };

  get AdminRouter() {
    return AdminRouter;
  }

  get routePath() {
    return AdminRouter;
  }
  get sourceList() {
    return metaStore.sourceDestinationList;
  }

  get shiftList() {
    return metaStore.shiftList;
  }

  get vehicleOwnerList() {
    return metaStore.vehicleOwnerList;
  }

  async created() {
    metaStore.loadSourceDestinationDD();
    metaStore.loadShift();
    metaStore.loadAllVehicleOwner();
    this.search(this.filter);
  }

  user_type() {
    return helper.getUserType();
  }

  momentTime(time: any) {
    return helper.toMomentTime(time);
  }
  edit(id: any) {
    this.$router.push(AdminRouter.VehicleScheduleCreate + "?id=" + id);
  }

  async onPageChange(val: number) {
    this.filter.page = val;
    this.search(this.filter);
  }

  async filterVehicleSchedule() {
    this.filter.page = 1;
    this.search(this.filter);
  }

  async clearFilter() {
    this.filter = {
      page: 1,
    };
    this.search(this.filter);
  }

  async search(filter: any) {
    await scheduleStore.loadAllSchedule(filter);
    this.scheduleList = scheduleStore.vehicleScheduleList;
  }

  submit(e: any) {
    scheduleStore.createSchedule();
  }
}
